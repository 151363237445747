<template>
  <div class="stu-report-refactor scroll-container">
    <div class="scroll-page">
      <div class="top flex-align">
        <img src="@/assets/report/redLogo.png" alt="" class="logo" />
      </div>
      <div class="report-time">
        <span>学期：{{ $route.query.termKeyName }}</span>
        <span>报告时间：{{ currentTime }}</span>
      </div>
      <h4 class="title">{{ reportInfo.userName }}同学的测评报告</h4>
      <div class="main-wrapper">
        <div class="main">
          <div class="top-panel flex-align-between">
            <ul class="flex-align score-list">
              <li v-for="(item, index) in basicData" :key="index" class="score-item flex-column">
                <p class="score-value">
                  {{ item.value }}<span class="score-unit">{{ item.unit }}</span>
                </p>
                <p class="score-name">{{ index }}</p>
              </li>
            </ul>
            <div class="line"></div>
            <img :src="integrationLevelMap[reportInfo.integrationLevel]" alt="" class="level" />
          </div>
          <div class="bottom-panel flex-between">
            <div class="panel-item">
              <h5>书写平均成绩</h5>
              <el-table :data="tableData1" stripe show-summary :summary-method="getTableAverage" sum-text="全部"
                class="table">
                <el-table-column prop="structure" label="分类" align="center">
                </el-table-column>
                <el-table-column prop="gridSize" label="占格" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.gridSize > 0 ? scope.row.gridSize : '--' }}
                  </template>
                </el-table-column>
                <el-table-column prop="comboRel" label="结构" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.comboRel > 0 ? scope.row.comboRel : '--' }}
                  </template>
                </el-table-column>
                <el-table-column prop="strokeRel" label="笔画" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.strokeRel > 0 ? scope.row.strokeRel : '--' }}
                  </template>
                </el-table-column>
                <el-table-column prop="glyphGrav" label="重心" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.glyphGrav > 0 ? scope.row.strokeRel : '--' }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="panel-item">
              <h5>书写等级分布情况</h5>
              <el-table :data="tableData2" stripe show-summary sum-text="全部" class="table">
                <el-table-column prop="structure" label="分类" align="center"></el-table-column>
                <el-table-column prop="total" label="总字数" align="center"> </el-table-column>
                <el-table-column prop="level4" label="优秀" align="center"> </el-table-column>
                <el-table-column prop="level3" label="良好" align="center"> </el-table-column>
                <el-table-column prop="level2" label="合格" align="center"> </el-table-column>
                <el-table-column prop="level1" label="待提升" align="center"> </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-page">
      <div class="main-wrapper second-wrapper">
        <div class="main second-main flex-column">
          <div class="top-module">
            <div class="toolbar flex-between">
              <button class="test-paper-btn csp" @click="showImgPreview">点击查看原试卷</button>
              <el-image ref="imagePreview" class="img-preview" style="width: 100px; height: 100px"
                :src="reportInfo.imgList[0]" :preview-src-list="reportInfo.imgList">
              </el-image>
              <ul class="flex-align structure-list">
                <li v-for="(item, index) in wordStructureList" :key="index" class="structure-item flex-align"
                  :class="{ active: activeStructure === index }" @click="switchStructure(item, index)">
                  <img :src="activeStructure === index ? item.activeIcon : item.icon" alt="" class="structure-icon" />{{
                    item.name }}
                </li>
              </ul>
            </div>
            <div class="all-words">
              <div class="flex-align-between">
                <div class="checked-group">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                  </el-checkbox>
                  <el-checkbox-group v-model="checkedList" @change="handleChecked" style="display: inline-block">
                    <el-checkbox v-for="city in checkedOptions" :label="city.value" :key="city.value">{{ city.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <span class="tips"><img src="../../assets/report/click.png" alt="" />【点击单字查看书写评价】</span>
              </div>
              <ul class="words">
                <li class="word-item" v-for="(item, index) in wordList" :key="index"
                  :class="{ activeWord: activeWord === index }" @click="selecedWord(item, index)">
                  <img class="word-img" src="../../assets/assistant/tian.png" alt="十字格子" />
                  <img class="word-img" :src="item.wordSkillCover" alt="技巧" />
                  <img class="word-img" :src="item.wordShadowCover" alt="阴影" />
                  <img class="word-img" :src="item.imagePath" alt="手写字" />
                  <div class="score-bg">
                    <img src="../../assets/report/score.png" alt="" />
                    <span class="score">{{ handleScore(item) }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="bottom-module flex-column">
            <h5>单字点评</h5>
            <div class="comment flex-column">
              <!-- 评分模块 -->
              <div class="score-header flex-align-between">
                <div class="dimension-scores flex-align">
                  <div v-for="(item, index) in scores" :key="index" class="dimension-scores-item">
                    <img :src="item.label" alt="" class="dimension-scores-label" />
                    <div class="dimension-scores-value">{{ item.value }}</div>
                  </div>
                </div>
                <div class="star-value">
                  <img :src="n <= starValue ? starIcon : emptyStarIcon" alt="" v-for="n in 5" :key="n" class="star" />
                </div>
              </div>
              <!-- 对比模块 -->
              <div class="character-comparison flex-align-between">
                <button :class="['left-btn', 'csp', { 'able': isPreAble }]" @click="preWord"><i
                    class="el-icon-arrow-left"></i></button>
                <ul class="characers flex-align-between">
                  <li class="characer" v-for="(item, index) in characerMap" :key="index">
                    <p class="characer-title">{{ item.title }}</p>
                    <div class="characer-item">
                      <img v-for="(sItem) in item.imgs" :key="sItem" :src="sItem" alt="" class="characer-img" />
                    </div>
                  </li>
                </ul>
                <button :class="['left-btn', 'csp', { 'able': isNextAble }]" @click="nextWord"><i
                    class="el-icon-arrow-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      checkAll: true,
      checkedList: [1, 2, 3, 4],
      checkedOptions: [{
        label: "优秀",
        value: 4,
      },
      {
        label: "良好",
        value: 3,
      },
      {
        label: "合格",
        value: 2,
      },
      {
        label: "待提升",
        value: 1,
      },
      ],
      isIndeterminate: false,

      currentTime: "",
      wordList: [],
      status: {
        4: "优秀",
        3: "良好",
        2: "合格",
        1: "待提升",
      },
      basicData: {
        书写测评: {
          value: "25",
          unit: "字",
        },
        正确率: {
          value: "36",
          unit: "%",
        },
        平均得分: {
          value: "85",
          unit: "分",
        },
      },
      integrationLevelMap: {
        1: require("../../assets/report/needs- improvement.png"),
        2: require("../../assets/report/pass.png"),
        3: require("../../assets/report/good.png"),
        4: require("../../assets/report/excellent.png"),
      },
      tableData1: [],
      tableData2: [],
      activeStructure: 0,
      wordStructureList: [{
        name: "全部",
        icon: require("../../assets/report/word-all.png"),
        activeIcon: require("../../assets/report/white-word-all.png"),
      },],
      staticStructureMap: {
        上下结构: {
          name: "上下",
          code: "上下结构",
          icon: require("../../assets/report/structure-1.png"),
          activeIcon: require("../../assets/report/white-structure-1.png"),
        },
        左右结构: {
          name: "左右",
          code: "左右结构",
          icon: require("../../assets/report/structure-2.png"),
          activeIcon: require("../../assets/report/white-structure-2.png"),
        },
        半包围结构: {
          name: "半包围",
          code: "半包围结构",
          icon: require("../../assets/report/structure-3.png"),
          activeIcon: require("../../assets/report/white-structure-3.png"),
        },
        独体结构: {
          name: "独体字",
          code: "独体结构",
          icon: require("../../assets/report/structure-4.png"),
          activeIcon: require("../../assets/report/white-structure-4.png"),
        },
        上中下结构: {
          name: "上中下",
          code: "上中下结构",
          icon: require("../../assets/report/structure-5.png"),
          activeIcon: require("../../assets/report/white-structure-5.png"),
        },
        全包围结构: {
          name: "全包围",
          code: "全包围结构",
          icon: require("../../assets/report/structure-6.png"),
          activeIcon: require("../../assets/report/white-structure-6.png"),
        },
        左中右结构: {
          name: "左中右",
          code: "左中右结构",
          icon: require("../../assets/report/structure-7.png"),
          activeIcon: require("../../assets/report/white-structure-7.png"),
        },
        框架结构: {
          name: "框架",
          code: "框架结构",
          icon: require("../../assets/report/structure-8.png"),
          activeIcon: require("../../assets/report/white-structure-8.png"),
        },
      },
      levelList: [{
        level: null,
        name: "全部",
      },
      {
        level: 4,
        name: "优秀",
      },
      {
        level: 3,
        name: "良好",
      },
      {
        level: 2,
        name: "合格",
      },
      {
        level: 1,
        name: "待提升",
      },
      ],
      activeWord: 0,
      reportInfo: {
        imgList: ['']
      },
      starValue: 0,
      emptyStarIcon: require("../../assets/report/empty-star.png"),
      starIcon: require("../../assets/report/star.png"),
      selectStructure: null,
      selectLevel: null,
      scores: [{
        label: require("../../assets/report/grid.png"),
        value: '--'
      },
      {
        label: require("../../assets/report/structure.png"),
        value: '--'
      },
      {
        label: require("../../assets/report/stroke.png"),
        value: '--'
      },
      {
        label: require("../../assets/report/balance.png"),
        value: '--'
      },
      ],
      characerMap: [{
        id: 1,
        title: "原字",
        imgs: []
      },
      {
        id: 2,
        title: "待测字",
        imgs: []
      },
      {
        id: 3,
        title: "AI智能对比",
        imgs: []
      },
      ],
      defaultWordInfo: {
        word: null,
        gridSize: null,
        glyphGrav: null,
        strokeRel: null,
        comboRel: null,
        integration: null,
        imagePath: null,
        resFilename: null,
        wordCover: null,
        wordShadowCover: null,
        wordSkillCover: null,
      },
      currentWord: {},
      isPreAble: false,
      isNextAble: false,
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedList = val ? [1, 2, 3, 4] : [];
      this.isIndeterminate = false;
      if (this.checkedList.length === 0) {
        this.wordList = [];
        this.currentWord = this.defaultWordInfo;
        this.changeCurrentWord()
        return;
      }
      this.getUserWordsRecord();
    },
    handleChecked(value) {
      console.log(value);
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checkedOptions.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkedOptions.length;
      if (value.length === 0) {
        this.wordList = [];
        return;
      }
      this.getUserWordsRecord();
    },

    async getStuData() {
      this.tableData = [];
      let data = {
        termKey: this.$route.query.termKey, // 20232,
        userId: this.$route.query.userId, // 390990,
        examType: this.$route.query.reportValue, // 1前测 2后测 4月测
        testId: this.$route.query.monthValue,
      };
      let resData = await this.$Api.Report.getStuDataV2(data);
      const reportInfo = resData.data;
      this.reportInfo = resData.data;
      console.log("reportInfo", reportInfo);
      console.log("Number(reportInfo.integrity)", Number(reportInfo.integrity));
      // 配置第一页
      this.basicData["书写测评"].value = reportInfo.wordCount > 0 ? reportInfo.wordCount : '--'
      this.basicData["正确率"].value = reportInfo.integrity > 0 ?  reportInfo.integrity: '--'
      this.basicData["平均得分"].value = reportInfo.avgScore > 0 ? reportInfo.avgScore : '--'
      console.log("this.basicData",)
      console.log("reportInfo.writeAvgScore", reportInfo.writeAvgScore)
      const newTabledata1 = reportInfo.writeAvgScore.filter((item) => item.structure !== "全部");

      this.tableData1 = newTabledata1;
      const newTabledata2 = reportInfo.levelDist.filter((item) => item.structure !== "全部");
      this.tableData2 = newTabledata2;
      // 配置第二页
      const newStructureList = [];
      reportInfo.structureList.forEach((item) => {
        newStructureList.push(this.staticStructureMap[item]);
      });
      this.wordStructureList = [...this.wordStructureList, ...newStructureList];
    },
    // 获取用户汉字列表成绩
    async getUserWordsRecord() {
      let data = {
        termKey: this.$route.query.termKey, // 20232,
        userId: this.$route.query.userId, // 390990,
        examType: this.$route.query.reportValue, // 1前测 2后测 4月测
        testId: this.$route.query.monthValue,
        structure: this.selectStructure,
        integrationLevelList: this.checkedList,
      };
      let resData = await this.$Api.Report.getWordByStructure(data);
      console.log("resData", resData);
      const wordList = resData.data;
      if (!wordList || wordList.length < 1) {
        console.error("子列表为空 request data:", data);
        return;
      }
      this.wordList = wordList;
      if (wordList && wordList.length > 0) {
        this.currentWord = wordList[0];
        this.changeCurrentWord();
        this.activeWord = 0
      }
    },
    handleScore(item) {
      return Number(item.integration).toFixed(0);
    },
    // 选择文字结构
    switchStructure(item, index) {
      this.activeStructure = index;
      this.selectStructure = index === 0 ? null : this.wordStructureList[index].code;
      this.getUserWordsRecord();
    },
    // 选择文字结构
    switchLevel(item, index) {
      console.log("item", item, "index", index, "selectLevel", this.selectLevel);
      this.selectLevel = item.level;
      // this.getUserWordsRecord()
    },
    // 选中单字
    selecedWord(item, index) {
      console.log('item', item, 'index', index)
      this.activeWord = index;
      this.currentWord = item;
      this.changeCurrentWord();
    },
    changeCurrentWord() {
      this.scores[0].value = this.currentWord.gridSize > 0 ? this.currentWord.gridSize : '--'
      this.scores[1].value = this.currentWord.comboRel > 0 ? this.currentWord.comboRel : '--'
      this.scores[2].value = this.currentWord.strokeRel > 0 ? this.currentWord.strokeRel : '--'
      this.scores[3].value = this.currentWord.glyphGrav > 0 ? this.currentWord.glyphGrav : '--'
      const bg = require('../../assets/assistant/tian.png')
      this.characerMap[0].imgs = [bg, this.currentWord.wordCover]
      this.characerMap[1].imgs = [bg, this.currentWord.imagePath]
      this.characerMap[2].imgs = [bg, this.currentWord.wordSkillCover, this.currentWord.wordShadowCover, this
        .currentWord.imagePath
      ]
      // console.log('currentWord', this.currentWord)
      this.starValue = this.currentWord.integration && Number(this.currentWord.integration) > 0 ?
        this.computeLevel(Number(this.currentWord.integration)) : 0
      // console.log('starValue', this.starValue)
      this.isNextAble = this.activeWord < (this.wordList.length - 1)
      this.isPreAble = this.activeWord > 0
    },
    nextWord() {
      this.activeWord++;
      this.currentWord = this.wordList[this.activeWord]
      this.changeCurrentWord();
    },
    preWord() {
      this.activeWord--;
      this.currentWord = this.wordList[this.activeWord]
      this.changeCurrentWord();
    },
    computeLevel(score) {
      if (score >= 85) {
        return 4
      }
      if (score >= 70 && score < 85) {
        return 3
      }
      if (score >= 60 && score < 70) {
        return 2
      }
      if (score < 60) {
        return 1
      }
      return 5
    },
    showImgPreview() {
      const imgElement = this.$refs.imagePreview.$el.querySelector('img');
      if (imgElement) {
        imgElement.click();
      }
    },
    getTableAverage(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "全部"; // 第一列显示“平均值”标签
          return;
        }

        // 只处理数值类型的列（例如分数）
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          const avg = values.reduce((acc, cur) => acc + cur, 0) / data.length;
          sums[index] = avg.toFixed(2); // 保留两位小数
        } else {
          sums[index] = "N/A";
        }
        sums[index] = sums[index] && sums[index] > 0 ? sums[index] : '--'
      });


      return sums;
    },
  },
  mounted() {
    this.getStuData();
    this.getUserWordsRecord();
    this.currentTime = moment().format("YYYY.MM.DD");
    this.currentWord = this.defaultWordInfo
    this.changeCurrentWord();
  },
};
</script>
<style lang="less" scoped>
// @import url('./less/new_report_stu_zhushou.less');
.stu-report-refactor {
  background-color: #f4f7f9;

  &.scroll-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }

  .scroll-page {
    height: 100vh;
    scroll-snap-align: start;
  }

  .top {
    width: 100%;
    height: 60px;
    background: linear-gradient(-90deg, #00b384, #01b791);

    .logo {
      width: 126px;
      margin-left: 203px;
    }
  }

  .report-time {
    line-height: 50px;
    padding: 0 100px;
    background-color: #fff;
    text-align: right;
    color: #c2c2c2;

    &>span {
      display: inline-block;

      &:last-child {
        margin-left: 35px;
      }
    }
  }

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #00b589;
    line-height: 81px;
  }

  .main-wrapper {
    padding: 0 203px;
    height: 80%;
    overflow: hidden;
  }

  .main {
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    padding: 64px 55px;
    box-sizing: border-box;
  }

  .score-item {
    margin-right: 40px;
    color: #fff;
    width: 345px;
    height: 255px;
    line-height: 1;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    font-family: "Microsoft YaHei";

    &:first-child {
      background-image: url("../../assets/report/bg-2.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &:nth-child(2) {
      background-image: url("../../assets/report/bg-1.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &:last-child {
      background-image: url("../../assets/report/bg-3.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 0;
    }

    .score-value {
      font-size: 60px;
    }

    .score-unit {
      font-size: 24px;
      vertical-align: 3px;
    }

    .score-name {
      font-size: 30px;
      margin-top: 19px;
    }
  }

  .line {
    width: 1px;
    height: 161px;
    background: #d2d2d2;
    margin: 0 87px 0 79px;
  }

  .level {
    width: 219px;
    height: 258px;
    margin-right: 57px;
  }

  .bottom-panel {
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 2px;
    width: 100%;
    box-sizing: border-box;

    .panel-item {
      width: 45%;
      padding: 0 2px;
    }

    h5 {
      font-size: 26px;
      font-weight: 600;
      color: #666;
    }

    .table {
      border: 1px solid #e7e7e7;
      border-radius: 10px;
      margin-top: 40px;

      ::v-deep th.el-table__cell {
        background: rgba(1, 181, 140, 0.1);
        color: rgba(1, 181, 140, 1);
        font-size: 21px;
        font-weight: 400;
      }

      ::v-deep .el-table--striped {
        background-color: #eeeeee;
      }

      ::v-deep .el-table__cell {
        font-size: 19px;
        color: #333;
        border-bottom: 0;

        div {
          line-height: 40px;
        }
      }

      ::v-deep .el-table__footer-wrapper tbody td.el-table__cell {
        border-top: 0;
        background-color: #fcfcfc;
        font-weight: 600;
      }

      ::v-deep .el-table__footer-wrapper tbody th.el-table__cell.gutter {
        background-color: #fcfcfc;
      }
    }
  }

  .second-wrapper {
    padding: 27px 203px;
    height: 100%;
    box-sizing: border-box;
  }

  .second-main {
    padding: 25px 55px;
  }

  .top-module {
    .toolbar {
      align-items: flex-start;

      .img-preview {
        display: none;
      }
    }

    .test-paper-btn {
      background: #f8f8f8;
      border-radius: 10px;
      padding: 0 22px 0 49px;
      line-height: 36px;
      color: #01b58c;
      position: relative;

      &:hover {
        background: rgba(248, 248, 248, 0.5);
      }

      &::before {
        content: " ";
        position: absolute;
        left: 23px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 24px;
        background-image: url("../../assets/report/dianji-shouzhi@2x.png");
      }
    }

    .structure-list {
      padding: 0 7px;
    }

    .structure-item {
      font-family: "Microsoft YaHei";
      font-size: 19px;
      width: 120px;
      height: 45px;
      border-radius: 10px 10px 0 0;
      background: #f8f8f8;
      color: #999999;
      margin-right: 8px;
      justify-content: center;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: #fff;
        background: #01b58c;
      }

      .structure-icon {
        margin-right: 3px;
        width: 20px;
      }
    }

    .all-words {
      border-radius: 10px;
      border: 1px solid #e7e7e7;
      padding: 20px 35px;
    }

    .checked-group {
      ::v-deep(.el-checkbox) {
        margin-right: 58px;

        .el-checkbox__input.is-focus .el-checkbox__inner {
          border-color: #00b384;
        }

        .el-checkbox__inner {
          width: 18px;
          height: 18px;
          vertical-align: 2px;

          &:hover {
            border-color: #00b384;
          }
        }

        &.is-checked {
          .el-checkbox__label {
            color: #00b384;
          }

          .el-checkbox__inner {
            background-color: #00b384;
            border-color: #00b384;

            &::after {
              width: 5px;
              height: 10px;
              top: 0px;
              left: 5px;
            }
          }
        }

        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #00b384;
          border-color: #00b384;

          &::before {
            top: 7px;
          }
        }

        .el-checkbox__label {
          font-size: 18px;
        }
      }
    }

    .tips {
      color: #999;
      font-size: 17px;

      img {
        vertical-align: -8px;
      }
    }

    .words {
      margin-top: 23px;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      column-gap: 52px;
      row-gap: 27px;
      height: 330px;
      overflow: auto;
      padding-bottom: 5px;
    }

    .word-item {
      width: 143px;
      height: 143px;
      border: 3px solid #ff8181;
      position: relative;
      cursor: pointer;

      &.activeWord,
      &:hover {
        border: 3px solid #01b58c;
      }

      .word-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        /* 图片宽度填满容器 */
        height: 100%;
        /* 图片高度填满容器 */
      }
    }

    .score-bg {
      position: absolute;
      right: -27px;
      top: -5px;

      .score {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -75%);
        color: #fff;
        font-size: 23px;
        font-weight: 600;
        font-style: italic;
      }
    }
  }

  .bottom-module {
    flex: 1;

    h5 {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 26px;
      color: #666666;
      margin: 20px 0;
    }

    .comment {
      border-radius: 10px;
      border: 1px solid #e7e7e7;
      padding: 25px 55px;
      flex: 1;
    }

    .dimension-scores {
      flex: 1;
    }

    .dimension-scores-item {
      margin: 0 75px;
      text-align: center;

      &:first-child {
        margin-left: 0;

        .dimension-scores-value {
          color: #d38e09;
        }
      }

      &:last-child {
        margin-right: 0;

        .dimension-scores-value {
          color: #4ba7f5;
        }
      }

      &:nth-child(2) .dimension-scores-value {
        color: #f88396;
      }

      &:nth-child(3) .dimension-scores-value {
        color: #48dacf;
      }
    }

    .dimension-scores-value {
      font-size: 58px;
      margin-top: 8px;
      line-height: 1;
    }

    .star-value {
      border-radius: 40px;
      background-color: rgba(238, 238, 238, 0.25);
      padding: 8px 61px;

      .star {
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .character-comparison {
      margin-top: 25px;
      flex: 1;
    }

    .left-btn {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 3px solid #e7e7e7;
      color: #e7e7e7;
      text-align: center;
      font-weight: 600;
      cursor: no-drop;

      &.able {
        border: 3px solid #36c3a3;
        color: #36c3a3;
        cursor: pointer;

        &:hover {
          color: #fff;
          background-color: #36c3a3;
          border: 3px solid #36c3a3;
        }
      }

      .el-icon-arrow-left,
      .el-icon-arrow-right {
        font-size: 28px;
        line-height: 37px;
      }
    }

    .characers {
      flex: 1;
      justify-content: space-around;
    }

    .characer-title {
      font-size: 20px;
      color: #999999;
      margin-bottom: 17px;
      text-align: center;
    }

    .characer-item {
      position: relative;
      width: 143px;
      height: 143px;

      .characer-img {
        position: absolute;
        top: 0;
        left: 0;
        border: 1xp solid skyblue;
        // width: 135px;
        // height: 135px;
        // max-width: 100%;
        width: 100%;
        height: 100%;

      }

    }

  }
}
</style>